<template>
  <div class="home">
    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">

          <ul class="nav nav-admin">
            <li>
              <router-link to="/account/overview">
                <i class="material-icons">dashboard</i>
                <span>Übersicht</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/reservations">
                <i class="material-icons">today</i>
                <span>Reservierungen</span>
              </router-link>
            </li>
            <li class="active">
              <router-link to="/account/payment_methods">
                <i class="material-icons">account_balance</i>
                <span>Bezahlmethoden</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/settings">
                <i class="material-icons">settings</i>
                <span>Einstellungen</span>
              </router-link>
            </li>
          </ul>

        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Bezahlmethoden</li>
            </ul>
            <div class="header-actions">
              <p><a href="/account/payment_methods/new" class="button button-orange button-small">Bezahlmethode hinzufügen ›</a></p>
            </div>
          </header>

          <table class="table" style="margin-top:20px">
            <thead>
              <tr>
                <th width="50%">Typ</th>
                <th width="50%">Letzte 4 Stellen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment_method in payment_methods" v-bind:key="payment_method.id">
                <td v-if="payment_method.stripe_type == 'sepa_debit'">
                  SEPA Lastschrift
                </td>
                <td v-if="payment_method.stripe_type == 'card'">
                  Kreditkarte
                </td>
                <td>**** **** **** {{ payment_method.stripe_last4 }}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

    </div>

  </div>
</template>


<script>
import axios from 'axios'

export default {
  name: 'home',
  data () {
    return {
      payment_methods: []
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/user/payment_methods', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.payment_methods = response.data.payment_methods;
      })
      .catch(error => {
        console.log(error);
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
